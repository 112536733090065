export default function Hero() {
   return (
        <>
            <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
                <div className="hero-container" data-aos="fade-in">
                    <h1>Jullian Abinsay</h1>
                    <p>I'm a <span className="typed" data-typed-items="Network Engineer, Web Developer, Freelancer"></span></p>
                    {/*<p>&lt;&gt; I'm a <span class="typed" data-typed-items="Network Admin, Developer, Freelancer"></span>&lt;/&gt;</p>*/}
                </div>
            </section>
        </>
    )
}