export default function Skills() {


    return (
        <>
            <section id="skills" className="skills section-bg">
                <div className="container">

                    <div className="section-title">
                        <h2>Technical Proficiencies</h2>
                        <p>Below are some of the skills I've picked up along the years. I also enjoy picking up new skills and staying current with technology's trends.</p>
                    </div>

                    <div className="row skills-content">

                        <div className="col-6" data-aos="fade-up">
                            <h4>Network Engineering</h4>
                            <div className='row'>

                                <div className='col-12 col-md-6'>
                                    <h6>Network</h6>
                                    <div className="progress">
                                        <span className="skill">Architecture <i className="val"></i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="100"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                    <div className="progress">
                                        <span className="skill">Maintenance <i className="val"></i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="100"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                    <div className="progress">
                                        <span className="skill">Monitoring <i className="val"></i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="100"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="progress">
                                        <span className="skill">Troubleshooting <i className="val"></i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="100"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="progress">
                                        <span className="skill">Disaster Recovery <i className="val"></i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="100"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="progress">
                                        <span className="skill">Windows Networks <i className="val"></i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="100"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="progress">
                                        <span className="skill">Linux Networks <i className="val"></i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="75"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>

                                    <h6>Business</h6>
                                    <div className="progress">
                                        <span className="skill">Business Continuity <i className="val"></i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="100"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                    <div className="progress">
                                        <span className="skill">Software Integration <i className="val"></i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="100"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                    <div className="progress">
                                        <span className="skill">Process Automation <i className="val"></i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="100"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                    <div className="progress">
                                        <span className="skill">Vendor Relations <i className="val"></i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="100"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                    <div className="progress">
                                        <span className="skill">User Training <i className="val"></i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="100"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>

                        <div className="col-6" data-aos="fade-up" data-aos-delay="100">
                            <h4 className='mb-3'>Web Development</h4>

                            <div className='row'>

                                <div className='col-12 col-md-6'>

                                    <h6>Front End</h6>
                                    <div className="progress">
                                        <span className="skill">HTML <i className="val">5 Years</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="100"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                    <div className="progress">
                                        <span className="skill">CSS <i className="val">5 Years</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="100"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                    <div className="progress">
                                        <span className="skill">JavaScript <i className="val">3 Years</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="80"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="progress">
                                        <span className="skill">React <i className="val">1 Year</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="60"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                    <div className="progress">
                                        <span className="skill">Bootstrap <i className="val">4 Years</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="100"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                    <div className="progress">
                                        <span className="skill">SASS <i className="val">1 Year</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="50"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>

                                    <h6>Back End</h6>
                                    <div className="progress">
                                        <span className="skill">AWS <i className="val">5 Years</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="90"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="progress">
                                        <span className="skill">Apache <i className="val">5 Years</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="100"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="progress">
                                        <span className="skill">Laravel <i className="val">2 Years</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="75"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                    <div className="progress">
                                        <span className="skill">Node <i className="val">1 Year</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="60"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                    <div className="progress">
                                        <span className="skill">MySQL <i className="val">4 Years</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="80"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                    <div className="progress">
                                        <span className="skill">MongoDB <i className="val">1 Year</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="55"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <div className='row'>

                                <div className='col-12 col-md-6'>

                                    <h6>Development Tools</h6>
                                    <div className="progress">
                                        <span className="skill">Git <i className="val">3 Years</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="75"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="progress">
                                        <span className="skill">Test Driven Development <i className="val">1 Year</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="55"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    {/*<div className="progress">*/}
                                    {/*    <span className="skill">Debugging <i className="val">55%</i></span>*/}
                                    {/*    <div className="progress-bar-wrap">*/}
                                    {/*        <div className="progress-bar" role="progressbar" aria-valuenow="55"*/}
                                    {/*             aria-valuemin="0" aria-valuemax="100"></div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                </div>

                                <div className='col-12 col-md-6'>

                                    <h6>DevOps</h6>
                                    <div className="progress">
                                        <span className="skill">Github Actions <i className="val">1 Year</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="55"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="progress">
                                        <span className="skill">Bash Scripting <i className="val">3 Years</i></span>
                                        <div className="progress-bar-wrap">
                                            <div className="progress-bar" role="progressbar" aria-valuenow="80"
                                                 aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}