export default function Portfolio() {


    return (
        <>
            <section id="portfolio" className="portfolio section-bg">
                <div className="container">

                    <div className="section-title">
                        <h2>Portfolio</h2>
                        {/*<p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem.*/}
                        {/*    Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit*/}
                        {/*    alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>*/}
                    </div>

                    <div className="row" data-aos="fade-up">
                        <div className="col-lg-12 d-flex justify-content-center">
                            <ul id="portfolio-flters">
                                <li data-filter="*" className="filter-active">All</li>
                                <li data-filter=".filter-web">Web</li>
                                <li data-filter=".filter-app">App</li>
                                {/*<li data-filter=".filter-card">Card</li>*/}
                            </ul>
                        </div>
                    </div>

                    <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="100">

                        <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                            <div className="portfolio-wrap">
                                <img src="assets/img/portfolio/roof.png" className="img-fluid" alt=""/>
                                <div className="portfolio-links">
                                    <a href="assets/img/portfolio/roof.png" data-gallery="portfolioGallery"
                                       className="portfolio-lightbox" title="Roof Jax Advantage"><i className="bx bx-plus"></i></a>
                                    <a href="https://roofjaxadvantage.com" title="More Details"><i className="bx bx-link"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                            <div className="portfolio-wrap">
                                <img src="assets/img/portfolio/fts.png" className="img-fluid" alt=""/>
                                <div className="portfolio-links">
                                    <a href="assets/img/portfolio/fts.png" data-gallery="portfolioGallery"
                                       className="portfolio-lightbox" title="Florida Tech Support"><i className="bx bx-plus"></i></a>
                                    <a href="https://floridatech.support" title="More Details"><i className="bx bx-link"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                            <div className="portfolio-wrap">
                                <img src="assets/img/portfolio/memory-reps.png" className="img-fluid" alt=""/>
                                <div className="portfolio-links">
                                    <a href="assets/img/portfolio/memory-reps.png" data-gallery="portfolioGallery"
                                       className="portfolio-lightbox" title="Memory Reps Application"><i className="bx bx-plus"></i></a>
                                    <a href="https://app.memoryreps.com" title="More Details"><i className="bx bx-link"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                            <div className="portfolio-wrap">
                                <img src="assets/img/portfolio/memory-reps-site.png" className="img-fluid" alt=""/>
                                <div className="portfolio-links">
                                    <a href="assets/img/portfolio/memory-reps-site.png" data-gallery="portfolioGallery"
                                       className="portfolio-lightbox" title="Memory Reps Site"><i className="bx bx-plus"></i></a>
                                    <a href="https://www.memoryreps.com" title="More Details"><i className="bx bx-link"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                            <div className="portfolio-wrap">
                                <img src="assets/img/portfolio/smith-endo.png" className="img-fluid" alt=""/>
                                <div className="portfolio-links">
                                    <a href="assets/img/portfolio/smith-endo.png" data-gallery="portfolioGallery"
                                       className="portfolio-lightbox" title="Smith Endodontics"><i className="bx bx-plus"></i></a>
                                    <a href="https://www.smithendo.co/" title="More Details"><i className="bx bx-link"></i></a>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}