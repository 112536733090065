export default function About() {

    return (
        <>
            <section id="about" className="about">
                <div className="container">

                    <div className="section-title">
                        <h2>About</h2>
                        <p></p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4" data-aos="fade-right">
                            <img src="assets/img/profile-img.jpg" className="img-fluid" alt=""/>
                        </div>
                        <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <h3>Network Engineer &amp; Web Developer.</h3>
                            <p className="fst-italic">
                                IT Specialist with 10 years of experience managing networks and 5 years of experience developing on the web.
                            </p>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li><i className="bi bi-chevron-right"></i> <strong>Birthday:</strong> <span>8 April 1990</span>
                                        </li>
                                        <li><i className="bi bi-chevron-right"></i> <strong>Website:</strong>
                                            <span>www.abinsay.com</span></li>
                                        <li><i className="bi bi-chevron-right"></i> <strong>City:</strong> <span>Jacksonville, Florida</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li><i className="bi bi-chevron-right"></i> <strong>Age:</strong>
                                            <span>31</span></li>
                                        {/*<li><i class="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>Master</span></li>*/}
                                        <li><i className="bi bi-chevron-right"></i> <strong>Email:</strong>
                                            <span>jullian@abinsay.com</span></li>
                                        <li><i className="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>+1 904 321 9777</span>
                                        </li>
                                        {/*<li><i class="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Available</span></li>*/}
                                    </ul>
                                </div>
                            </div>
                            {/*<p>*/}
                            {/*    Officiis eligendi itaque labore et dolorum mollitia officiis optio vero. Quisquam sunt*/}
                            {/*    adipisci omnis et ut. Nulla accusantium dolor incidunt officia tempore. Et eius omnis.*/}
                            {/*    Cupiditate ut dicta maxime officiis quidem quia. Sed et consectetur qui quia repellendus*/}
                            {/*    itaque neque. Aliquid amet quidem ut quaerat cupiditate. Ab et eum qui repellendus omnis*/}
                            {/*    culpa magni laudantium dolores.*/}
                            {/*</p>*/}
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}