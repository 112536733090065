import logo from './logo.svg';
import './App.css';
import { useEffect } from "react";
import Facts from "./components/Facts";
import Skills from "./components/Skills";
import Resume from "./components/Resume";
import Portfolio from "./components/Portfolio";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
import About from "./components/About";
import Hero from "./components/Hero";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {

  useEffect(() => {
    require('./main')
  }, [])

  return (
    <>
      {/*// <!-- ======= Mobile nav toggle button ======= -->*/}
      <i class="bi bi-list mobile-nav-toggle d-xl-none"></i>

      {/*// <!-- ======= Header ======= -->*/}
      <Header/>

      {/*// <!-- ======= Hero Section ======= -->*/}
      <Hero/>

      <main id="main">

        {/*// <!-- ======= About Section ======= -->*/}
        <About/>

        {/*// <!-- ======= Facts Section ======= -->*/}
        {/*<Facts/>*/}

        {/*// <!-- ======= Skills Section ======= -->*/}
        <Skills/>

        {/*// <!-- ======= Resume Section ======= -->*/}
        <Resume/>

        {/*// <!-- ======= Portfolio Section ======= -->*/}
        <Portfolio/>

        {/*// <!-- ======= Services Section ======= -->*/}
        {/*<Services/>*/}

        {/*// <!-- ======= Testimonials Section ======= -->*/}
        {/*<Testimonials/>*/}

        {/*// <!-- ======= Contact Section ======= -->*/}
        <Contact/>

      </main>

        {/*// <!-- ======= Footer ======= -->*/}
        <Footer/>

      {/*<a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>*/}
    </>
  );
}

export default App;
