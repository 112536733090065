export default function Resume() {

    return (
        <>
            <section id="resume" className="resume">
                <div className="container">

                    <div className="section-title">
                        <h2>Professional Experience</h2>
                        {/*<p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem.*/}
                        {/*    Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit*/}
                        {/*    alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>*/}
                    </div>

                    <div className="row">
                        {/*<div className="col-lg-6" data-aos="fade-up">*/}
                        {/*    <h3 className="resume-title">Sumary</h3>*/}
                        {/*    <div className="resume-item pb-0">*/}
                        {/*        <h4>Alex Smith</h4>*/}
                        {/*        <p><em>Innovative and deadline-driven Graphic Designer with 3+ years of experience*/}
                        {/*            designing and developing user-centered digital/print marketing material from initial*/}
                        {/*            concept to final, polished deliverable.</em></p>*/}
                        {/*        <ul>*/}
                        {/*            <li>Portland par 127,Orlando, FL</li>*/}
                        {/*            <li>(123) 456-7891</li>*/}
                        {/*            <li>alice.barkley@example.com</li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}

                        {/*    <h3 className="resume-title">Education</h3>*/}
                        {/*    <div className="resume-item">*/}
                        {/*        <h4>Master of Fine Arts &amp; Graphic Design</h4>*/}
                        {/*        <h5>2015 - 2016</h5>*/}
                        {/*        <p><em>Rochester Institute of Technology, Rochester, NY</em></p>*/}
                        {/*        <p>Qui deserunt veniam. Et sed aliquam labore tempore sed quisquam iusto autem sit. Ea*/}
                        {/*            vero voluptatum qui ut dignissimos deleniti nerada porti sand markend</p>*/}
                        {/*    </div>*/}
                        {/*    <div className="resume-item">*/}
                        {/*        <h4>Bachelor of Fine Arts &amp; Graphic Design</h4>*/}
                        {/*        <h5>2010 - 2014</h5>*/}
                        {/*        <p><em>Rochester Institute of Technology, Rochester, NY</em></p>*/}
                        {/*        <p>Quia nobis sequi est occaecati aut. Repudiandae et iusto quae reiciendis et quis Eius*/}
                        {/*            vel ratione eius unde vitae rerum voluptates asperiores voluptatem Earum molestiae*/}
                        {/*            consequatur neque etlon sader mart dila</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                            {/*<h3 className="resume-title">Professional Experience</h3>*/}
                            <div className="resume-item">
                                <h4>Systems Administrator</h4>
                                <h5>10/2011 - 08/13</h5>
                                <p><em>SmartStream IT Solutions, Jacksonville, FL </em></p>
                                <ul>
                                    <li>Built and managed networks for small and medium sized companies.
                                    </li>
                                    <li>Serviced client requests using a ticketing system.
                                    </li>
                                    <li>Managed Active Directory, Exchange, and Terminal Servers for larger clients
                                    </li>
                                </ul>
                            </div>
                            <div className="resume-item">
                                <h4>Director of IT</h4>
                                <h5> 05/2012 - 08/2017</h5>
                                <p><em>First Coast Care, Jacksonville, FL</em></p>
                                <ul>
                                    <li>Designed, Implemented, and Maintained a HIPAA Compliant Network for Business
                                    </li>
                                    <li>Integrated Vendor Software and Supported End Users</li>
                                    <li>Improved Business Processes with Software Tools and Automated Scripts
                                    </li>
                                </ul>
                            </div>
                            <div className="resume-item">
                                <h4>Freelance Web Developer</h4>
                                <h5>06/2016 - Present</h5>
                                <p><em>Freelance, Jacksonville, FL</em></p>
                                <ul>
                                    <li>3 Years of LAMP Development with 2 of those years on Laravel
                                    </li>
                                    <li>1 Year of MERN Development
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                            {/*<h3 className="resume-title">Professional Experience</h3>*/}
                            <div className="resume-item">
                                <h4>Director of IT</h4>
                                <h5>08/2018 - Present</h5>
                                <p><em>Title Boxing Club ( Jacksonville, Florida / Clermont, Florida / Columbia, South Carolina )</em></p>
                                <ul>
                                    <li>Manage Networks for 3 Locations under same owner
                                    </li>
                                    <li>Migrated to Cloud, Integrated Business/Vendor Software, and Improved Business Processes</li>
                                </ul>
                            </div>
                            <div className="resume-item">
                                <h4>Network Engineer</h4>
                                <h5>06/2019 - Present</h5>
                                <p><em>Florida Tech Support, Jacksonville, FL</em></p>
                                <ul>
                                    <li>Build and manage networks for small and medium sized companies
                                    </li>
                                    <li>Install networks from bare offices</li>
                                    <li>Service Client Requests and Improve Business Processes</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}