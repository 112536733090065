export default function Contact() {

    return (
        <>
            <section id="contact" className="contact">
                <div className="container">

                    <div className="section-title">
                        <h2>Contact</h2>
                        {/*<p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem.*/}
                        {/*    Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit*/}
                        {/*    alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>*/}
                    </div>

                    <div className="row" data-aos="fade-in">

                        <div className="col-lg-5 d-flex align-items-center">
                            <div className="info h-100 d-flex">
                                <div className='row align-self-center'>

                                    <div className="address">
                                        <i className="bi bi-geo-alt"></i>
                                        <h4>Location:</h4>
                                        <p>Jacksonville, Florida</p>
                                    </div>

                                    <div className="email">
                                        <i className="bi bi-envelope"></i>
                                        <h4>Email:</h4>
                                        <p>jullian@abinsay.com</p>
                                    </div>

                                    <div className="phone">
                                        <i className="bi bi-phone"></i>
                                        <h4>Call:</h4>
                                        <p>+1 904 321 9777</p>
                                    </div>

                                    {/*<div className="phone">*/}
                                    {/*    <img src='/assets/img/vcard.PNG' style={{ width: '250px'}}/>*/}
                                    {/*</div>*/}
                                </div>

                                {/*<iframe*/}
                                {/*    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d881472.4436954578!2d-82.24356395581266!3d30.343794877967937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e5b716f1ceafeb%3A0xc4cd7d3896fcc7e2!2sJacksonville%2C%20FL!5e0!3m2!1sen!2sus!4v1634927268714!5m2!1sen!2sus"*/}
                                {/*    frameBorder="0"*/}
                                {/*    style={{*/}
                                {/*        border: 0,*/}
                                {/*        width: '100%',*/}
                                {/*        height: '290px'*/}
                                {/*    }}*/}
                                {/*    // style="border:0; width: 100%; height: 290px;"*/}
                                {/*    allowFullScreen*/}
                                {/*></iframe>*/}
                            </div>

                        </div>

                        <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                            <div className="info d-flex justify-content-center">
                                {/*<h5>VCard:</h5>*/}
                                <img src='/assets/img/vcard.PNG' className='img-fluid'/>
                            </div>
                            {/*<form action="forms/contact.php" method="post" role="form" className="php-email-form">*/}
                            {/*    <div className="row">*/}
                            {/*        <div className="form-group col-md-6">*/}
                            {/*            <label htmlFor="name">Your Name</label>*/}
                            {/*            <input type="text" name="name" className="form-control" id="name" required/>*/}
                            {/*        </div>*/}
                            {/*        <div className="form-group col-md-6">*/}
                            {/*            <label htmlFor="name">Your Email</label>*/}
                            {/*            <input type="email" className="form-control" name="email" id="email" required/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className="form-group">*/}
                            {/*        <label htmlFor="name">Subject</label>*/}
                            {/*        <input type="text" className="form-control" name="subject" id="subject" required/>*/}
                            {/*    </div>*/}
                            {/*    <div className="form-group">*/}
                            {/*        <label htmlFor="name">Message</label>*/}
                            {/*        <textarea className="form-control" name="message" rows="3" required></textarea>*/}
                            {/*    </div>*/}
                            {/*    <div className="my-3">*/}
                            {/*        <div className="loading">Loading</div>*/}
                            {/*        <div className="error-message"></div>*/}
                            {/*        <div className="sent-message">Your message has been sent. Thank you!</div>*/}
                            {/*    </div>*/}
                            {/*    <div className="text-center">*/}
                            {/*        <button type="submit">Send Message</button>*/}
                            {/*    </div>*/}
                            {/*</form>*/}
                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}